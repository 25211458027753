import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=237d35a2&scoped=true&"
import script from "./Events.vue?vue&type=script&lang=js&"
export * from "./Events.vue?vue&type=script&lang=js&"
import style0 from "./Events.vue?vue&type=style&index=0&id=237d35a2&scoped=true&lang=css&"
import style1 from "./Events.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237d35a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VContainer,VDialog,VSpacer,VToolbar,VToolbarItems,VToolbarTitle})
